import * as React from "react"

import Footer from "@components/Footer/footer"
import Header from "@components/Header/header"

export default function Layout({
  uri,
  children,
}: {
  uri: string
  children: React.ReactNode
}) {
  const isPost = uri.startsWith("/blog/")
  return (
    <>
      <Header isPost={isPost} />
      <main>{children}</main>
      <Footer />
    </>
  )
}
