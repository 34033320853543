module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MonSiteTranquille est un agence à taille humaine qui réalise des site internet performants. No Wordpress. JAMStack only pour des performances inégalées.","short_name":"MonSiteTranquille","start_url":"/","icon":"src/images/favicon.png","background_color":"#fff","theme_color":"#f1b808","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7bba8d0873852d5ee125efaca158107d"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
